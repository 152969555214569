import React from "react"

import Layout from "../layouts/default"

import Breadcrumb from "../components/breadcrumb"
import Constrain from "../components/constrain"
import Bullet from "../components/bullet"
import BulletList from "../components/bullet-list"
import Heading from "../components/heading"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import Stack from "../components/stack"
import Text from "../components/text"
import TextLink from "../components/textlink"

const DatenschutzPage = () => (
  <Layout backdrop="aussen">
    <Seo
      title="Datenschutz"
      description="Alle Informationen zur Datenerhebung und -verarbeitung"
    />
    <Stack space={12}>
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Datenschutz",
              link: "/datenschutz",
            },
          ]}
        />
        <PageTitle>Datenschutz</PageTitle>
      </Stack>
      <Heading level={5}>
        Hinweisblatt mit Informationen gemäß Art. 13 der
        Datenschutz-Grundverordnung
      </Heading>
      <Constrain align="left">
        <Stack>
          <Stack space={6}>
            <Heading>§ 1 Verantwortlicher im Sinne der DSGVO</Heading>
            <Text>
              ist die Universitätsstadt Tübingen, Am Markt 1, 72070 Tübingen,
              E-Mail:{" "}
              <TextLink href="mailto:stadt@tuebingen.de">
                stadt@tuebingen.de
              </TextLink>
              , vertreten durch Oberbürgermeister Boris Palmer.
            </Text>
          </Stack>
          <Stack space={6}>
            <Heading>§ 2 Kontaktaufnahme</Heading>
            <Text>
              Zum behördlichen Datenschutzbeauftragten der Universitätsstadt
              Tübingen können Sie über die E-Mail Adresse{" "}
              <TextLink href="mailto:datenschutz@tuebingen.de">
                datenschutz@tuebingen.de
              </TextLink>{" "}
              Kontakt aufnehmen.
            </Text>
          </Stack>
          <Stack space={6}>
            <Heading>
              § 3 Grundsätze der Verarbeitung personenbezogener Daten
            </Heading>
            <Text>
              Wir verarbeiten personenbezogene Daten grundsätzlich nur, soweit
              dies zur Bereitstellung einer funktionsfähigen Website oder zur
              Erbringung der angefragten Leistungen erforderlich ist.
            </Text>
          </Stack>
          <Stack space={6}>
            <Heading>
              § 4 Erhebung personenbezogener Daten bei Besuch unserer Website
            </Heading>
            <Text>
              Bei jedem Aufruf einer Webseite übermittelt der Browser des
              Nutzers Daten an den Server, die technisch erforderlich sind, um
              die Webseite anzuzeigen. Folgende Daten werden dabei erfasst:
            </Text>
            <BulletList>
              <Bullet>IP-Adresse</Bullet>
              <Bullet>Datum und Uhrzeit des Webbesuchs</Bullet>
              <Bullet>Inhalt der Anforderung (konkrete Seite)</Bullet>
              <Bullet>Zugriffsstatus/HTTP-Statuscode</Bullet>
              <Bullet>jeweils übertragene Datenmenge</Bullet>
              <Bullet>Webseite, von der die Anforderung kommt</Bullet>
              <Bullet>
                Kennung des Browsers (enthält üblicherweise Angaben zu Name,
                Version und Sprache von Browser und Betriebssystem)
              </Bullet>
            </BulletList>
            <Text>
              Die Daten werden aus Gründen der Stabilität und Sicherheit (z.B.
              zur Untersuchung technischer Fehler oder zur Aufklärung von
              Missbrauchs- oder Betrugshandlungen) in den Logfiles unseres
              Systems gespeichert. Eine Speicherung dieser Daten zusammen mit
              anderen personenbezogenen Daten des Nutzers findet nicht statt.
              Die Logfile-Informationen werden nach 7 Tagen gelöscht, oder die
              IP-Adressen werden anonymisiert, sodass keine Personenbezug mehr
              gegeben ist. Daten, deren weitere Aufbewahrung zu Beweiszwecken
              erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
              Vorfalls von der Löschung ausgenommen.
            </Text>
            <Text>Rechtsgrundlage: Art. 6 Abs. 1 S. 1 lit. f DSGVO</Text>
            <Text>
              Widerspruchs- und Beseitigungsmöglichkeit: keine, da die Erfassung
              der Daten für den Betrieb der Internetseite erforderlich ist.
            </Text>
          </Stack>

          <Stack space={6}>
            <Heading>§ 5 Einsatz von Cookies</Heading>
            <Text>Diese Webseite verwendet keine Cookies.</Text>
          </Stack>
          <Stack space={6}>
            <Heading>§ 6 Newsletter</Heading>
            <Text>
              Für den Verwand unseres Newsletters nutzen wir den Anbieter
              rapidmail (rapidmail GmbH, Wentzingerstraße, 21, 79106 Freiburg,
              Deutschland). Die von Ihnen für den Zweck des Newsletterbezugs
              eingegeben Daten (Name und E-Mail-Adresse) werden auf den Servern
              von rapidmail in Deutschland gespeichert. Wenn Sie keine Analyse
              durch rapidmail möchten, müssen Sie den Newsletter abbestellen.
              Hierfür stellen wir in jeder Newsletternachricht einen
              entsprechenden Link zur Verfügung.
            </Text>
            <Text>
              Für den Zweck der Analyse enthalten die mit rapidmail versandten
              E-Mails ein sog. Tracking-Pixel, das sich beim Öffnen der E-Mail
              mit den Servern von rapidmail verbindet. Auf diese Weise kann
              festgestellt werden, ob eine Newsletter-Nachricht geöffnet wurde.
              Des Weiteren können wir mit Hilfe von rapidmail ermitteln, ob und
              welche Links in der Newsletternachricht angeklickt werden. Bei
              allen Links in der E-Mail handelt es sich um sogenannte
              Tracking-Links, mit denen Ihre Klicks gezählt werden können.
            </Text>
            <Text>
              Wenn Sie unseren Newsletter abonnieren, werden die Daten in der
              jeweiligen Eingabemaske an den für die Verarbeitung
              Verantwortlichen übermittelt. Die Anmeldung zu unserem Newsletter
              erfolgt in einem sog. Double-Opt-In-Verfahren. D.h. Sie erhalten
              nach der Anmeldung eine E-Mail, in der Sie um die Bestätigung
              Ihrer Anmeldung gebeten werden. Diese Bestätigung ist notwendig,
              damit sich niemand mit fremden E-Mail-Adressen anmelden kann. Bei
              der Anmeldung zum Newsletter werden Ihre IP-Adresse sowie Datum
              und Uhrzeit der Registrierung gespeichert. Dies dient dazu, einen
              Missbrauch der Dienste oder der E-Mail-Adresse der betroffenen
              Person zu verhindern. Eine Weitergabe der Daten an Dritte erfolgt
              nicht. Eine Ausnahme besteht dann, wenn eine gesetzliche
              Verpflichtung zur Weitergabe besteht. Die Daten werden
              ausschließlich für den Versand des Newsletters verwendet. Eine
              Übermittlung der Daten in Drittstaaten findet nicht statt.
            </Text>
            <Text>
              Das Abonnement des Newsletters kann jederzeit gekündigt werden.
              Ebenso kann die Einwilligung in die Speicherung der
              personenbezogenen Daten jederzeit widerrufen werden. Zu diesem
              Zweck findet sich in jedem Newsletter ein entsprechender Link.
              Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung zum
              Newsletter durch den Nutzer ist bei Vorliegen einer Einwilligung
              des Nutzers Art. 6 Abs. 1 lit. a) DSGVO.
            </Text>
            <Text>
              Die Ihrerseits im Rahmen der Einwilligung für den Zweck des
              Newsletters bei uns gespeicherten Daten werden von uns bis zu
              Ihrer Austragung aus dem Newsletter gespeichert und nach der
              Abbestellung des Newsletters sowohl von unseren Servern als auch
              von den Servern von rapidmail gelöscht. Daten, die zu anderen
              Zwecken bei uns gespeichert wurden (z.B. Anmeldungen zu Führungen
              oder Veranstaltungen) bleiben hiervon unberührt.
            </Text>
            <Text>
              Sie haben die Möglichkeit, Ihre Einwilligung zur Datenverarbeitung
              jederzeit mit Wirkung für die Zukunft zu widerrufen. Die
              Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge
              bleibt vom Widerruf unberührt.
            </Text>
            <Text>
              Weitere Hinweise zur Datensicherheit unseres Newsletters mit
              rapidmail finden Sie unter:{" "}
              <TextLink href="https://www.rapidmail.de/datensicherheit">
                https://www.rapidmail.de/datensicherheit
              </TextLink>
              . Näheres zu den Analyse-Funktionen von rapidmail entnehmen Sie
              folgendem Link:{" "}
              <TextLink href="https://www.rapidmail.de/wissen-und-hilfe">
                https://www.rapidmail.de/wissen-und-hilfe
              </TextLink>
            </Text>
          </Stack>

          <Stack space={6}>
            <Heading>§ 7 Betroffenenrechte</Heading>
            <Text>
              Soweit die gesetzlichen und persönlichen Voraussetzungen erfüllt
              sind, steht Ihnen das Recht auf Auskunft über die verarbeiteten
              personenbezogenen Daten (Art. 15 DSGVO), das Recht auf
              Berichtigung unrichtiger Daten (Art. 16 DSGVO), das Recht auf
              Löschung (Art. 17 DSGVO) oder Einschränkung der Datenverarbeitung
              (Art 18 DSGVO) und das Recht auf Widerspruch gegen die
              Datenverarbeitung wegen besonderer Umstände zu (Art. 21 DSGVO).
              Außerdem haben Sie das Recht, ihre personenbezogenen Daten, in
              einem übertragbaren Format zu erhalten (Art. 20 DSGVO).
            </Text>
            <Text>
              Jede betroffene Person hat außerdem das Recht auf Beschwerde bei
              der Aufsichtsbehörde (Der Landesbeauftragte für den Datenschutz
              und die Informationsfreiheit, Königsstraße 10a, 70173 Stuttgart,
              poststelle@lfdi.bwl.de), wenn sie der Ansicht sind, dass ihre
              personenbezogenen Daten rechtswidrig verarbeitet wurden.
            </Text>
          </Stack>
        </Stack>
      </Constrain>
    </Stack>
  </Layout>
)

export default DatenschutzPage
